var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: `key_${_vm.key}` }, [
    _c(
      "div",
      { staticClass: "kt-portlet kt-portlet--height-fluid recent-messages" },
      [
        _c(
          "div",
          { staticClass: "kt-portlet__head kt-portlet__head--noborder" },
          [
            _c("div", { staticClass: "kt-portlet__head-label" }, [
              _c("div", { staticClass: "kt-input-icon kt-input-icon--left" }, [
                _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: "Filter...",
                    debounce: "500",
                  },
                  on: {
                    input: (event) => _vm.changeFilter(event.target.value),
                  },
                }),
                _vm._m(0),
              ]),
            ]),
            _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
              _vm.toggleShowDeleted !== null
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-bold",
                      class: {
                        "btn-danger": _vm.showDeleted,
                        "btn-label-danger": !_vm.showDeleted,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.toggleShowDeleted.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.showDeleted ? "Hide Deleted" : "Deleted") +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        ),
        _c("div", { staticClass: "kt-portlet__body" }, [
          _c(
            "div",
            {
              staticClass: "kt-scroll",
              staticStyle: {
                height: "55vh",
                "overflow-y": "auto",
                "overflow-x": "hidden",
              },
            },
            [
              _c("div", { staticClass: "kt-widget5" }, [
                _vm.messages.length
                  ? _c(
                      "div",
                      _vm._l(_vm.messages, function (message, idx) {
                        return _c(
                          "div",
                          {
                            key: `message_${message.communicationMessageId}_${idx}`,
                            staticClass: "kt-widget5__item",
                          },
                          [
                            _c("div", { staticClass: "kt-widget5__content" }, [
                              _c("div", { staticClass: "kt-widget5__pic" }, [
                                !message.deleted
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "kt-badge kt-badge--lg",
                                        class: {
                                          "kt-badge--success":
                                            !message.isSchoolAnnouncement,
                                          "kt-badge--warning":
                                            message.isSchoolAnnouncement,
                                        },
                                      },
                                      [
                                        message.isSchoolAnnouncement
                                          ? _c("span", [
                                              _c("i", {
                                                staticClass: "fa fa-bullhorn",
                                              }),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    message.author.substring(
                                                      0,
                                                      2
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kt-badge kt-badge--lg kt-badge--danger",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-trash-alt",
                                        }),
                                      ]
                                    ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "kt-widget5__section" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "kt-widget5__title message-subject",
                                      attrs: {
                                        to: {
                                          name: "ViewMessage",
                                          params: {
                                            messageId: message.messageId,
                                            studentEnrollmentId:
                                              _vm.studentEnrollmentId || null,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(message.messageSubject) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "kt-widget5__desc message-body",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(message.messageBody) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget5__info" },
                                    [
                                      _c("span", [_vm._v(_vm._s("From: "))]),
                                      _c(
                                        "span",
                                        { staticClass: "kt-font-info" },
                                        [_vm._v(_vm._s(message.author))]
                                      ),
                                      _c("span", [
                                        _vm._v(_vm._s("Last Active: ")),
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "kt-font-info" },
                                        [_vm._v(_vm._s(message.lastMessageAt))]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "kt-widget5__content" }, [
                              !message.messageRead
                                ? _c(
                                    "div",
                                    { staticClass: "kt-widget5__stats" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "kt-widget5__number kt-font-danger kt-font-md",
                                        },
                                        [_vm._v("New Message")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _c("div", [_vm._v(" No messages found ")]),
              ]),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "kt-input-icon__icon kt-input-icon__icon--left" },
      [_c("span", [_c("i", { staticClass: "la la-search" })])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }