<template>
<div :key="`key_${key}`">
    <div class="kt-portlet kt-bg-brand kt-portlet--skin-solid kt-portlet--height-fluid recent-messages">
        <div class="kt-portlet__head kt-portlet__head--noborder">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                    Recent Messages
                </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
                <span v-if="messages.filter((a) => !a.deleted).length > 1">
                    <button
                        v-if="recentMessageIdx > 0"
                        class="btn btn-icon btn-elevate btn-elevate-air"
                        @click.prevent.stop="prevMessage"
                    >
                        <i class="fa fa-arrow-left kt-font-light" />
                    </button>
                    <button
                        class="btn btn-icon btn-elevate btn-elevate-air"
                        @click.prevent.stop="nextMessage"
                    >
                        <i class="fa fa-arrow-right kt-font-light" />
                    </button>
                </span>
            </div>
        </div>
        <div class="kt-portlet__body">
            <div class="kt-widget7 kt-widget7--skin-light">
                <div
                    v-if="recentMessage"
                    class="kt-widget7__desc recent-subject"
                >
                    <router-link
                        :to="{ name: 'ViewMessage', params: { messageId: recentMessage.messageId, studentEnrollmentId: studentEnrollmentId || null } }"
                        class="kt-link kt-link--state kt-link--light"
                    >
                        {{ recentMessage.messageSubject }}
                    </router-link>
                </div>
                <div
                    v-else
                    class="kt-widget7__desc recent-subject"
                >
                    No Recent Message
                </div>
                <div
                    v-if="recentMessage"
                    class="kt-widget7__content"
                >
                    <div class="kt-widget7__userpic">
                        <div
                            class="kt-badge kt-badge--lg"
                            :class="{
                                'kt-badge--success': !recentMessage.isSchoolAnnouncement,
                                'kt-badge--warning': recentMessage.isSchoolAnnouncement,
                            }"
                        >
                            <span v-if="recentMessage.isSchoolAnnouncement">
                                <i class="fa fa-bullhorn" />
                            </span>
                            <span v-else>
                                {{ recentMessage.author.substring(0,2) }}
                            </span>
                        </div>
                    </div>
                    <div class="kt-widget7__info">
                        <h3 class="kt-widget7__username">
                            {{ recentMessage.author }}
                        </h3>
                        <span class="kt-widget7__time">
                            {{ recentMessage.lastMessageAt }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import UserAvatar from '../UserAvatar.vue';

export default {
    name: 'RecentMessages',
    components: {
        UserAvatar,
    },
    props: {
        messages: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            key: 0,
            recentMessageIdx: 0,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        recentMessage() {
            const { messages, recentMessageIdx } = this;
            return messages.filter((a) => !a.deleted).slice(0, 5)[recentMessageIdx] || null;
        },
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
    },
    watch: {
        messages() {
            this.key += 1;
        },
    },
    methods: {
        prevMessage() {
            const { messages, recentMessageIdx } = this;
            const recentMessages = messages.filter((a) => !a.deleted).slice(0, 5);
            if (recentMessageIdx - 1 < 0) {
                this.recentMessageIdx = recentMessages.length - 1;
            } else {
                this.recentMessageIdx -= 1;
            }
        },
        nextMessage() {
            const { messages, recentMessageIdx } = this;
            const recentMessages = messages.filter((a) => !a.deleted).slice(0, 5);
            if (recentMessageIdx + 1 >= recentMessages.length) {
                this.recentMessageIdx = 0;
            } else {
                this.recentMessageIdx += 1;
            }
        },
    },
};
</script>

<style scoped>
.recent-messages {
    min-height: 70vh !important;
}
.recent-subject {
    text-overflow: ellipsis !important;
}
</style>
