var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: `key_${_vm.key}` }, [
    _c(
      "div",
      {
        staticClass:
          "kt-portlet kt-bg-brand kt-portlet--skin-solid kt-portlet--height-fluid recent-messages",
      },
      [
        _c(
          "div",
          { staticClass: "kt-portlet__head kt-portlet__head--noborder" },
          [
            _vm._m(0),
            _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
              _vm.messages.filter((a) => !a.deleted).length > 1
                ? _c("span", [
                    _vm.recentMessageIdx > 0
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-icon btn-elevate btn-elevate-air",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.prevMessage.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-arrow-left kt-font-light",
                            }),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-icon btn-elevate btn-elevate-air",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.nextMessage.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-arrow-right kt-font-light",
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        _c("div", { staticClass: "kt-portlet__body" }, [
          _c("div", { staticClass: "kt-widget7 kt-widget7--skin-light" }, [
            _vm.recentMessage
              ? _c(
                  "div",
                  { staticClass: "kt-widget7__desc recent-subject" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "kt-link kt-link--state kt-link--light",
                        attrs: {
                          to: {
                            name: "ViewMessage",
                            params: {
                              messageId: _vm.recentMessage.messageId,
                              studentEnrollmentId:
                                _vm.studentEnrollmentId || null,
                            },
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.recentMessage.messageSubject) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c("div", { staticClass: "kt-widget7__desc recent-subject" }, [
                  _vm._v(" No Recent Message "),
                ]),
            _vm.recentMessage
              ? _c("div", { staticClass: "kt-widget7__content" }, [
                  _c("div", { staticClass: "kt-widget7__userpic" }, [
                    _c(
                      "div",
                      {
                        staticClass: "kt-badge kt-badge--lg",
                        class: {
                          "kt-badge--success":
                            !_vm.recentMessage.isSchoolAnnouncement,
                          "kt-badge--warning":
                            _vm.recentMessage.isSchoolAnnouncement,
                        },
                      },
                      [
                        _vm.recentMessage.isSchoolAnnouncement
                          ? _c("span", [
                              _c("i", { staticClass: "fa fa-bullhorn" }),
                            ])
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.recentMessage.author.substring(0, 2)
                                  ) +
                                  " "
                              ),
                            ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "kt-widget7__info" }, [
                    _c("h3", { staticClass: "kt-widget7__username" }, [
                      _vm._v(" " + _vm._s(_vm.recentMessage.author) + " "),
                    ]),
                    _c("span", { staticClass: "kt-widget7__time" }, [
                      _vm._v(
                        " " + _vm._s(_vm.recentMessage.lastMessageAt) + " "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" Recent Messages "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }